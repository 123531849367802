import { useState, useEffect, useRef } from 'react';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import TextFieldFilter from 'src/ems/components/common/Filter/TextFieldFilter';

const Filter = ({ query, onQueryChange, searchPlaceholder }) => {
  const [open, setOpen] = useState(true);
  const textFieldRef = useRef(null);

  useEffect(() => {
    if (open) {
      if (textFieldRef.current) {
        textFieldRef.current.focus();
      }
    }
  }, [open]);

  return (
    <>
      <TextFieldFilter
        query={query}
        onQueryChange={onQueryChange}
        icon={<SearchTwoToneIcon />}
        placeholder={searchPlaceholder}
        id="searchTextField" // Assign an ID to the TextFieldFilter
        inputRef={textFieldRef} // Pass the ref
      />
    </>
  );
};
export default Filter;
