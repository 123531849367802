import Tooltip from '@mui/material/Tooltip';
import LaunchIcon from '@mui/icons-material/Launch';
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton, styled } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';

const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
      transition: ${theme.transitions.create(['transform', 'background'])};
      transform: scale(1);
      transform-origin: center;
  
      &:hover {
          transform: scale(1.1);
      }
    `
  );

  const renderActions = (theme, rowData, handleViewAction?, handleDeleteAction?, handleEditAction?, handleDownloadAction?, handleCloneAction?, handleBypassAction?, handleEmailAction?) => {

    return (
      <>
        {handleEmailAction ? (
          <Tooltip title="Send Remittance" arrow>
            <Button onClick={() => handleEmailAction(rowData)}>
              <AttachEmailIcon fontSize="small"/>
            </Button>  
          </Tooltip>          
        ) : null}       
        {handleCloneAction ? (
          <Tooltip title="Clone" arrow>
            <Button onClick={() => handleCloneAction(rowData)}>
              <FileCopyTwoToneIcon fontSize="small"/>
            </Button>  
          </Tooltip>          
        ) : null}   
        {handleCloneAction &&  (handleViewAction || handleDownloadAction || handleEditAction || handleDeleteAction) ? "|" : null}   
        {handleViewAction ? (
          <Tooltip title="View" arrow>
            <Button onClick={() => handleViewAction(rowData)}>
              <LaunchIcon fontSize="small"/>
            </Button>  
          </Tooltip>          
        ) : null}
        {handleViewAction &&  handleDownloadAction ? "|" : null}
        {handleDownloadAction ? (
            <Tooltip title="Download" arrow>
            <Button onClick={() => handleDownloadAction(rowData)}
            >
              <CloudDownloadIcon fontSize="small" />
            </Button>
          </Tooltip>
        ) : null}
        {(handleDownloadAction || handleViewAction) && handleEditAction ? "|" : null}
        {handleEditAction ? (
        <Tooltip title="Edit" arrow>
          <Button onClick={() => handleEditAction(rowData)}>
            <EditIcon />
          </Button>            
        </Tooltip>
        ) : null}  
        {(handleDownloadAction || handleViewAction || handleEditAction) && handleDeleteAction ? "|" : null}
        {handleDeleteAction ? (
        <Tooltip title="Delete" arrow>
          <Button onClick={() => handleDeleteAction(rowData)}>
            <DeleteIcon />
          </Button>  
        </Tooltip>
        ) : null} 
        {handleBypassAction ? "|" : null}  
        {handleBypassAction ? (
        <Tooltip title="Bypass Login" arrow>
          <Button onClick={() => handleBypassAction(rowData)}>
            <LockOpenIcon />
          </Button>  
        </Tooltip>
        ) : null}    
      </>
    );
  };

  export {
    renderActions
  }