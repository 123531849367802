import {
  Button,
  Card,
  CardActions,
  CircularProgress,
  FormHelperText
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyTwoTone';
import {
  DeepMap,
  FieldError,
  FieldValues,
  useForm,
  FormProvider
} from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import useMode from 'src/ems/hooks/useMode';
import { formActionType } from 'src/ems/enums/form.enums';

interface FormUtilsProps<T> {
  Component: React.FC<{ [key: string]: any }>;
  createEntity?: (data: T) => Promise<void>;
  updateEntity: (data: T) => Promise<void>;
  validationSchema: any;
  props: { [key: string]: any };
}

function FormUtils<T>({
  Component,
  createEntity,
  updateEntity,
  validationSchema,
  props
}: FormUtilsProps<T>) {
  const methods = useForm<T>({
    defaultValues: props.entity,
    resolver: yupResolver(validationSchema)
  });
  const { handleSubmit, setError, formState } = methods;
  const { enqueueSnackbar } = useSnackbar();
  let { disabled, createMode, viewMode } = useMode();

  const [state, setState] = useState({
    disabled: false,
    viewMode: false,
    createMode: false
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      disabled: disabled, // Update the disabled value in the state
      viewMode: viewMode,
      createMode: createMode
    }));
    
  }, [disabled, createMode, viewMode, props.hasAnyFurtherActionPostSubmit]);

  const { isSubmitting } = formState;

  const [editLabel, setEditLabel] = useState('Edit');
  const [cloneLabel, setCloneLabel] = useState('Clone');

  const onSubmit = async (data: T) => {
    const { isAddMode, entityType } = props;
    await handleAction(
      () => (state.createMode ? createEntity(data) : updateEntity(data)),
      `${
        formActionType[formActionType[state.createMode ? 'Create' : 'Update']]
      } ${entityType}`
    );
  };

  async function handleAction(
    action: () => Promise<void>,
    entityAction: string
  ) {
    const { location, navigate, toRoutePath, isHandleCondition, hasAnyFurtherActionPostSubmit } = props;
    try {
      await action();
      
      const rootPath = `/${location.pathname.split('/')[1]}`;
      if(!isHandleCondition) {
        enqueueSnackbar(`${entityAction} completed`, { variant: 'success' });
        setState({ ...state, disabled: true, viewMode: true });
      }else{
        setState({ ...state, disabled: !hasAnyFurtherActionPostSubmit, viewMode: !hasAnyFurtherActionPostSubmit, createMode: hasAnyFurtherActionPostSubmit });
      }
            
      setEditLabel('Edit');
      
      if(toRoutePath === "") return;

      navigate(
        `${rootPath}/${
          toRoutePath !== undefined ? toRoutePath : `${props.entityType}/list`
        }`
      );
    } catch (error) {
      enqueueSnackbar( error.response?.data ? error.response?.data : `${entityAction} failed`, { variant: 'error' });
      setError(formState.errors['apiError'], {
        message: error.response?.data || error.message
      });
    }
  }

  return (
    <FormProvider {...methods}>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!props.hideButtons ? (
            <CardActions sx={{ justifyContent: 'right' }}>
              {!state.viewMode && (
                <Button
                  /*           color="success" */
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size="1rem" />
                    ) : (
                      <DoneIcon />
                    )
                  }
                  disabled={isSubmitting}
                  type="submit"
                  /*                 variant="outlined" */
                  variant="contained"
                >
                  Save
                </Button>
              )}
              {!state.createMode && (
                <Button
                  sx={{visibility: props.isViewModeFromProps ? 'hidden' : 'visible'}}
                  variant="contained"
                  /*                   color="success"
                  variant="outlined" */
                  onClick={() => {
                    if (editLabel == 'Edit') {
                      setState({ ...state, disabled: false, viewMode: false });
                      setEditLabel('Cancel');
                    } else {
                      setState({ ...state, disabled: true, viewMode: true });
                      setEditLabel('Edit');
                    }
                  }}
                >
                  {editLabel}
                </Button>
              )}
              {/* {(props.haveClone === true && !props.entity.isCreate && editLabel === "Edit") && (
                <Button
                variant="contained"
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size="1rem" />
                  ) : (
                    <FileCopyTwoToneIcon />
                  )
                }
                onClick={() => {
                  const { location, navigate, entityType } = props;
                  const rootPath = `/${location.pathname.split('/')[1]}`;
                  navigate(
                    `${rootPath}/${entityType}/create`, { state: props.updatedEntity }                    
                  );
                }}
              >
                {cloneLabel}
              </Button>
              )
              } */}
            </CardActions>
          ) : null}
          <Component disabled={state.disabled} {...props} />
          {formState.errors['apiError'] && (
            <FormHelperText error={!!formState.errors['apiError']}>
              {formState.errors['apiError']
                ? `${
                    (
                      formState.errors['apiError'] as DeepMap<
                        FieldValues,
                        FieldError
                      >
                    ).message
                  }`
                : ''}
            </FormHelperText>
          )}
        </form>
      </Card>
    </FormProvider>
  );
}

export default FormUtils;
