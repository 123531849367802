import {
  Box,
  Button,
  CardActions,
  Divider,
  Grid,
  LinearProgress
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';
import useRefMounted from 'src/hooks/useRefMounted';
import { useDispatch, useSelector } from 'src/store';
import ClassOfServiceForm from './ClassOfServiceForm';
import { getDropDownReferences } from 'src/ems/slices/referenceDropDown';
import ClassOfServiceService from 'src/ems/services/supplier.class.of.service.service';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import Footer from 'src/components/Footer';
import List from './List/List';
import { checkUserHasRole } from 'src/ems/services/auth.service';
import { role } from 'src/ems/enums/roles.enums';
import ClassOfService from "src/ems/models/supplier_models/supplier_class_of_service_details";
import Filter from './List/TableSections/Filter';

const ClassOfServiceMain = () => {
  const methods = useForm();
  const isMountedRef = useRefMounted();
  const [open, setOpen] = useState(false);
  const [classOfServiceToEdit, setclassOfServiceToEdit] =
    useState<ClassOfService | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [classOfServiceList, setClassOfServiceList] = useState<ClassOfService[] | null>(null);

  const [query, setQuery] = useState('');
  const [airlines, setAirlines] = useState([]);
  let filteredData = [];
  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };
  const dispatch = useDispatch();
  const { references } = useSelector((state) => state.referenceDropDown);
    
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch(getDropDownReferences(signal));

    return () => {
      controller.abort();
    };
  }, [dispatch]);

  const handleOpen = (selectedClassOfService) => {
    if (selectedClassOfService !== null) setclassOfServiceToEdit(selectedClassOfService);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getClassOfServiceList = useCallback(
    async (signal?: any) => {
      setIsLoading(true);
      try {
        signal = typeof signal === 'string' ? null : signal;
        
        const { data: classOfServiceList } =
        await ClassOfServiceService.list(signal);
        setClassOfServiceList(classOfServiceList);  
        
        const { data: airlines } =
        await ClassOfServiceService.getAirlines(signal);
        setAirlines(airlines.filter(x => x.supplierCode !== ''));  
      } catch (error) {
        if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
          console.error(error);
        }
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getClassOfServiceList(signal).then((a) => {
      setIsLoading(false);
    })    
    .catch((error) => {
      setIsLoading(false);
    });

    return () => {
      controller.abort();
    };
  }, [getClassOfServiceList]);

  const applyFilters = (classOfServiceList, query) => {
    const properties = ['airline'];
    return classOfServiceList.filter((item) => {
      const matchesQuery =
        !query ||
        properties.some((property) => {
          const value = item[property];
          return value && value.toLowerCase().includes(query.toLowerCase());
        });

      return matchesQuery;
    });
  };

  const modalProps = {
    handleOpen,
    handleClose,
    open,
    classOfServiceList,
    classOfServiceToEdit,
    setclassOfServiceToEdit,
    getClassOfServiceList,
    setIsLoading,
    airlines,
    references
  };

  if(classOfServiceList){
    filteredData = applyFilters(classOfServiceList, query);
  }

  return (
    <>
    {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: '0 20px',
            marginBottom: 2
          }}
        >
          <LinearProgress sx={{ width: '100%' }} />
        </Box>
      ) : null}     
      <Helmet>
        <title>Class of Service</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <CardActions
          sx={{
            justifyContent: 'right',
            paddingBottom: 0,
            marginTop: -5,
            marginRight: 3
          }}
          >
            {checkUserHasRole(role.ADD_CLASS_OF_SERVICE) ?
            <Button
              type="submit"
              variant="contained"
              onClick={() => {
                handleOpen(null);
                return;
              }}
            >
              ADD
            </Button> : null}
        </CardActions>

        <FormProvider {...methods}>
          <Box p={3}>        
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center">       
                  <Grid item xs={12} md={12} sx={{ marginY: { xs: 2, sm: 2, md: 0}}}>   
                    <Filter query={query} onQueryChange={handleQueryChange} searchPlaceholder={"Search by airline..."} />
                  </Grid>              
                </Grid> 
              </Grid>             
            </Grid>    
          </Box>  
          <Divider />
          <Box p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                {filteredData.length > 0 ? 
                <List
                  data={filteredData}
                  editModalProps={modalProps}
                /> : null}
              </Grid>
            </Grid>
          </Box>
        </FormProvider>
        <ClassOfServiceForm modalProps={modalProps} classOfServiceList={filteredData} />
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default ClassOfServiceMain;
