const GridStyle = () => {
  return {
    '& .MuiDataGrid-toolbarContainer': {
      '& .MuiButton-text': {
        fontSize: '16px !important'
      },
      '& .MuiBadge-badge': {
        backgroundColor: '#074682'
      }
    },
    '& .MuiDataGrid-columnHeaders': {
      fontSize: 16
    },
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'whitesmoke'
      }
    }
  };
};

export default GridStyle;
