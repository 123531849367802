import { useCallback, useState } from 'react';
import { Grid } from '@mui/material';
import useRefMounted from 'src/hooks/useRefMounted';
import { useSnackbar } from 'notistack';
import columns from './columns';
import ConfirmModal from 'src/ems/components/common/ConfirmModal';
import ClassOfService from "src/ems/models/supplier_models/supplier_class_of_service_details";
import ClassOfServiceService from 'src/ems/services/supplier.class.of.service.service';
import DataTable from 'src/ems/components/common/DataTable';

const List = ({data, editModalProps}) => {
  const {    
    handleOpen,
    getClassOfServiceList,
    setIsLoading,
    airlines
  } = editModalProps;

  const isMountedRef = useRefMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setdisabled] = useState(false);
  const [selectedRow, setselectedRow] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const proceed = useCallback(async (handleClose, id) => {    
    try {  
      await ClassOfServiceService.deleteClassOfService(
        id
      );

      await getClassOfServiceList();
      setIsLoading(false);
      enqueueSnackbar(`Class of service deleted`, { variant: 'success' });
      setdisabled(false);
      handleClose();
    } catch (err) {
      enqueueSnackbar(`Error deleting Class of service`, { variant: 'error' });
      console.error(err);    
      setdisabled(false);  
      handleClose();
    }
  },[isMountedRef]);  

  const airline = airlines.filter(x => x.entityId === parseInt(selectedRow?.airlineId?.toString()))[0]?.name;
  const bookingClass = selectedRow?.bookingClass;
  const classType = selectedRow?.classTypeReference;

  const modalPropsDelete = {
    title: "",
    action: "Delete Class of service",
    handleClose : handleCloseDelete,
    open: openDelete,
    proceed,
    disabled,
    setdisabled,
    id: selectedRow?.classOfServiceId,
    msg: `Are you sure you want to delete this Airline ${airline} with Booking Class ${bookingClass} and ClassType ${classType}`
  }    
   return (    
    <>          
      <Grid item xs={12}>
          <DataTable
            defaultPageSize={10}   
            rows={data}
            columns={columns(handleOpenDelete, setselectedRow, handleOpen)}
            density={'compact'}
            getRowId={(row: ClassOfService) => row.classOfServiceId}
            hideColumnFilter={false}
          />                     
      </Grid>    
      <ConfirmModal props={modalPropsDelete}/>  
    </>
  );
};

export default List;
