export enum formAccessType {
  ViewMode = 1,
  CreateMode = 2,
  EditMode = 3
}

export enum formActionType {
  Create,
  List,
  Update,
  Delete
}

export enum formInputType{
  TextField,
  Dropdown
}

export enum PeriodType{
  Year = 84,
  Quarter = 85,
  Month = 86,
  Fortnight = 113,
  HalfYear = 132
}