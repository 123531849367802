
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, CircularProgress } from '@mui/material';

export default function ConfirmModal({
    props
  }) {
    const {open, handleClose, title, msg="Are you sure you want to delete this ", action="Delete", proceed, disabled, setdisabled, id, fromList, selectedValue} = props;
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>{action + " " + title}</Typography>
        </DialogTitle>
        <DialogContent>
          {msg + title + (!(msg + title).includes('?') ? " ?" : "")} 
        </DialogContent>
        <DialogActions>
          <Button 
            startIcon={
              disabled ? <CircularProgress size="1rem" /> : null
            }     
            disabled={disabled}     
            onClick={() => {
                        setdisabled(true);
                        proceed(handleClose, id, selectedValue, fromList);
                      }} 
            color="primary"
            variant="contained">
            Yes
          </Button>          
          <Button        
            onClick={() => {
                      handleClose();
                    }}
                  color="secondary"
                  variant="contained">No</Button>
        </DialogActions>
      </Dialog>          
    );
  }