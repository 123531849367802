import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useDispatch } from 'src/store';
import FormUtils from "src/ems/components/common/FormUtils";
import ClassOfServiceService from 'src/ems/services/supplier.class.of.service.service';
import { getReferences } from 'src/ems/slices/reference';
import ClassOfServiceModal from "./List/Modal";
import { classOfServiceDetailsSchema } from "src/ems/validations/schema/class_of_service_details";
import ClassOfService from "src/ems/models/supplier_models/supplier_class_of_service_details";

interface ClassOfServiceFormProps {
    modalProps: any;
    classOfServiceList: ClassOfService[];
  }

const ClassOfServiceForm: FC<ClassOfServiceFormProps> = ({    
    modalProps,
    classOfServiceList
  }) => {
    const location = useLocation();
    let navigate = useNavigate();
    const [selectedClassOfService, setselectedClassOfService] = useState<ClassOfService | null>(null);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const {getClassOfServiceList, ClassOfServiceToEdit, setIsLoading, references, airlines} = modalProps;

    async function updateClassOfService(data: ClassOfService) {         
      try {
        data.airline = airlines.filter(x => x.entityId === parseInt(data.airlineId?.toString()))[0]?.supplierCode;
        await ClassOfServiceService.update(
          data
        );

        await getClassOfServiceList();
        setIsLoading(false);
        enqueueSnackbar(`Class of service update completed`, { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(`Class of service update failed`, { variant: 'error' });
      }            
    }

    async function addClassOfService(data: ClassOfService) {     
      try {
        data.classOfServiceId = 0;
        data.airline = airlines.filter(x => x.entityId === parseInt(data.airlineId?.toString()))[0]?.supplierCode;
        await ClassOfServiceService.create(
          data
        );

        await getClassOfServiceList();
        setIsLoading(false);
        enqueueSnackbar(`Class of service create completed`, { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(`Class of service create failed`, { variant: 'error' });
      }          
    }

    const validationSchema = classOfServiceDetailsSchema;

    const updateEntity = updateClassOfService;
    const createEntity = addClassOfService;

    const props = {
      entity: ClassOfServiceToEdit,
      location,
      navigate,
      setselectedClassOfService,     
      selectedClassOfService,   
      entityType: 'Class of service',
      modalProps,
      classOfServiceList,
      updateClassOfService,
      addClassOfService,
      hideButtons: true,
      references
    };

    useEffect(() => {
      dispatch(getReferences());
    }, [dispatch]);

    return (
        <FormUtils
            Component={ClassOfServiceModal}
            updateEntity={updateEntity}
            createEntity={createEntity}
            validationSchema={validationSchema}
            props={props}
        />
    );
}
export default ClassOfServiceForm;
