import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

type Mode = {
  disabled: boolean;
  createMode: boolean;
  viewMode: boolean;
};

const useMode = () => {
  const [mode, setMode] = useState<Mode>({
    disabled: false,
    createMode: false,
    viewMode: false
  });
  const location = useLocation();

  useEffect(() => {
    const pathName: string = location.pathname;

    if (/view/.test(pathName)) {
      // Disable and enter view mode if the path name contains the word "view"
      setMode({ disabled: true, createMode: false, viewMode: true });
    } else if (/create/.test(pathName)) {
      // Enable create mode if the path name contains the word "create"
      setMode({ disabled: false, createMode: true, viewMode: false });
    } else {
      setMode({ disabled: false, createMode: false, viewMode: false }); // set back to normal mode if not in view or create mode
    }
  }, [location.pathname]);

  return mode;
};

export default useMode;
