// TextFieldFilter.tsx

import React from 'react';
import { TextField, InputAdornment } from '@mui/material';

const TextFieldFilter = ({
  query,
  onQueryChange,
  icon,
  placeholder,
  inputRef, // Add inputRef to props
  ...rest
}) => {
  return (
    <TextField
      {...rest}
      InputProps={{
        startAdornment: <InputAdornment position="start">{icon}</InputAdornment>
      }}
      onChange={onQueryChange}
      placeholder={placeholder}
      value={query}
      fullWidth
      variant="outlined"
      inputRef={inputRef} // Assign ref to TextField
    />
  );
};

export default TextFieldFilter;
