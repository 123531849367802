import type { ReactNode } from 'react';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import SupportIcon from '@mui/icons-material/Support';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PublicIcon from '@mui/icons-material/Public';
import ContactsIcon from '@mui/icons-material/Contacts';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import LandscapeIcon from '@mui/icons-material/Landscape';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { getUserMenu } from 'src/ems/services/menu.service';
import { formLabelClasses } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  isVisible?: boolean;
  isExpanded?: boolean;
  parent?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
  isVisible?: boolean;
}

export function getMenuItems() {
  // Check Authorisation
  let menuPermissions = getUserMenu();

  const menuItems: MenuItems[] = [
    {
      heading: 'General',
      isVisible: menuPermissions.isAuthorised_General,
      items: [
        /*       {
        name: 'Blueprints',
        icon: BackupTableTwoToneIcon,
        link: '',
        items: [
          {
            name: 'Extended sidebar',
            link: '/extended-sidebar/dashboards',
            badge: 'v3.0',
            badgeTooltip: 'Added in version 3.1'
          },
          {
            name: 'Accent header',
            link: '/accent-header/dashboards',
            badge: '',
            badgeTooltip: 'Updated'
          },
          {
            name: 'Accent sidebar',
            link: '/accent-sidebar/dashboards'
          },
          {
            name: 'Boxed sidebar',
            link: '/boxed-sidebar/dashboards'
          },
          {
            name: 'Collapsed sidebar',
            link: '/collapsed-sidebar/dashboards'
          },
          {
            name: 'Bottom navigation',
            link: '/bottom-navigation/dashboards'
          },
          {
            name: 'Top navigation',
            link: '/top-navigation/dashboards'
          }
        ]
      }, */
        /*  {
        name: 'Dashboards',
        icon: SmartToyTwoToneIcon,
        link: '/accent-sidebar/dashboards',
        items: [
          {
            name: 'Reports',
            link: 'dashboards/reports'
          },
          {
            name: 'Expenses',
            link: 'dashboards/expenses',
            badge: '',
            badgeTooltip: 'This is a dot tooltip example'
          },
          {
            name: 'Products',
            link: 'dashboards/products'
          },
          {
            name: 'Statistics',
            link: 'dashboards/statistics'
          },
          {
            name: 'Automation',
            link: 'dashboards/automation'
          },
          {
            name: 'Analytics',
            link: 'dashboards/analytics'
          },
          {
            name: 'Banking',
            link: 'dashboards/banking'
          },
          {
            name: 'Commerce',
            link: 'dashboards/commerce'
          },
          {
            name: 'Crypto',
            link: 'dashboards/crypto'
          },
          {
            name: 'Finance',
            link: 'dashboards/finance'
          },
          {
            name: 'Fitness',
            link: 'dashboards/fitness'
          },
          {
            name: 'Healthcare',
            link: '/accent-sidebar/dashboards/healthcare',
            items: [
              {
                name: 'Doctors',
                link: 'dashboards/healthcare/doctor'
              },
              {
                name: 'Hospital',
                link: 'dashboards/healthcare/hospital'
              }
            ]
          },
          {
            name: 'Helpdesk',
            link: 'dashboards/helpdesk'
          },
          {
            name: 'Learning',
            link: 'dashboards/learning'
          },
          {
            name: 'Monitoring',
            link: 'dashboards/monitoring'
          },
          {
            name: 'Tasks',
            link: 'dashboards/tasks'
          }
        ].filter(a => a.isVisible)
      }, */
        {
          name: 'Dashboards',
          icon: DashboardIcon,
          link: '/accent-sidebar/ems_dashboards',
          isVisible: menuPermissions.isAuthorised_EMS_Dashboard,
          isExpanded: true,
          items: [
            {
              name: 'Sales',
              link: '/accent-sidebar/ems_dashboards/sales_dashboard',
              isVisible: menuPermissions.isAuthorised_Sales_Dashboard,
              isExpanded: true,
              parent: 'EMS Dashboards'
            },
            {
              name: 'Management',
              link: '/accent-sidebar/ems_dashboards/management_dashboard',
              isVisible: menuPermissions.isAuthorised_Management_Dashboard,
              isExpanded: false,
              parent: 'EMS Dashboards'
            },
            {
              name: 'Product',
              link: '/accent-sidebar/ems_dashboards/product_dashboard',
              isVisible: menuPermissions.isAuthorised_Product_Dashboard,
              isExpanded: false,
              parent: 'EMS Dashboards'
            },
            {
              name: 'Highlights',
              link: '/accent-sidebar/ems_dashboards/highlights_dashboard',
              isVisible: menuPermissions.isAuthorised_Highlights_Dashboard,
              isExpanded: true,
              parent: 'EMS Dashboards'
            },
            {
              name: 'Turn Around',
              link: '/accent-sidebar/ems_dashboards/turnaround_dashboard',
              isVisible: menuPermissions.isAuthorised_Turnaround_Dashboard,
              isExpanded: true,
              parent: 'EMS Dashboards'
            },
          ].filter((a) => a.isVisible)
        },
        {
          name: 'Agents',
          icon: SupportAgentRoundedIcon,
          link: '/accent-sidebar/agent',
          isVisible: menuPermissions.isAuthorised_Agent,
          isExpanded: false,
          items: [
            {
              name: 'List',
              link: '/accent-sidebar/agent/list',
              isVisible: menuPermissions.isAuthorised_Agent_List,
              isExpanded: false,
              parent: 'Agents'
            },
            {
              name: 'Create',
              link: '/accent-sidebar/agent/create',
              isVisible: menuPermissions.isAuthorised_Agent_Create,
              isExpanded: false,
              parent: 'Agents'
            },
            {
              name: 'Agent Group',
              link: '/accent-sidebar/agent/agentgroup',
              isVisible: menuPermissions.isAuthorised_Agent_Agent_Group,
              isExpanded: false,
              parent: 'Agents'
            },,
            {
              name: 'Network Parent Group',
              link: '/accent-sidebar/agent/agentparentgroup',
              isVisible: menuPermissions.isAuthorised_Agent_Agent_Parent_Group,
              isExpanded: false,
              parent: 'Agents'
            },
          ].filter((a) => a.isVisible)
        },
        {
          name: 'Suppliers',
          icon: PublicIcon,
          link: '/accent-sidebar/supplier',
          isVisible: menuPermissions.isAuthorised_Supplier,
          isExpanded: false,
          items: [
            {
              name: 'List',
              link: '/accent-sidebar/supplier/list',
              isVisible: menuPermissions.isAuthorised_Supplier_List,
              isExpanded: false,
              parent: 'Suppliers'
            },
            {
              name: 'Create',
              link: '/accent-sidebar/supplier/create',
              isVisible: menuPermissions.isAuthorised_Supplier_Create,
              isExpanded: false,
              parent: 'Suppliers'
            },
            {
              name: 'Airline BSP',
              link: '/accent-sidebar/supplier/airlinebsp',
              isVisible: menuPermissions.isAuthorised_Airline_BSP,
              isExpanded: false,
              parent: 'Suppliers'
            },
            {
              name: 'Airline Offer',
              link: '/accent-sidebar/supplier/airlineoffer',
              isVisible: menuPermissions.isAuthorised_Airline_Offer,
              isExpanded: false,
              parent: 'Suppliers'
            },
            {
              name: 'Class Of Service',
              link: '/accent-sidebar/supplier/classofservice',
              isVisible: menuPermissions.isAuthorised_View_Class_of_service,
              isExpanded: false,
              parent: 'Suppliers'
            }
          ].filter((a) => a.isVisible)
        },
        {
          name: 'Contacts',
          icon: ContactsIcon,
          link: '/accent-sidebar/contact',
          isVisible: menuPermissions.isAuthorised_Contact,
          isExpanded: false,
          items: [
            {
              name: 'List',
              link: '/accent-sidebar/contact/list',
              isVisible: menuPermissions.isAuthorised_Contact_List,
              isExpanded: false,
              parent: 'Contacts'
            },
            // {
            //   name: 'Create',
            //   link: '/accent-sidebar/contact/create',
            //   isVisible: menuPermissions.isAuthorised_Contact_Create,
            //   isExpanded: false,
            //   parent: 'Contacts'
            // }
          ].filter((a) => a.isVisible)
        }

        /*         {
          name: 'Contracts',
          icon: HistoryEduIcon,
          isVisible: false,
          isExpanded: false,
          link: '/accent-sidebar/contracts',
          items: [
            {
              name: 'List',
              isVisible: false,
              link: '/accent-sidebar/contracts/list',
              isExpanded: false
            }
          ]
        } */
        /*       {
        name: 'Data Display',
        icon: HealthAndSafetyTwoToneIcon,
        badge: '',
        badgeTooltip: 'Tokyo contains over 250 data display blocks',
        link: '/accent-sidebar/blocks',
        items: [
          {
            name: 'Charts large',
            link: 'blocks/charts-large'
          },
          {
            name: 'Charts small',
            link: 'blocks/charts-small'
          },
          {
            name: 'Composed cards',
            link: 'blocks/composed-cards'
          },
          {
            name: 'Grids',
            link: 'blocks/grids'
          },
          {
            name: 'Icon cards',
            link: 'blocks/icon-cards'
          },
          {
            name: 'Image cards',
            link: 'blocks/image-cards'
          },
          {
            name: 'Lists large',
            link: 'blocks/lists-large'
          },
          {
            name: 'Lists small',
            link: 'blocks/lists-small'
          },
          {
            name: 'Navigation',
            link: 'blocks/navigation'
          },
          {
            name: 'Profile cards',
            link: 'blocks/profile-cards'
          },
          {
            name: 'Progress circular',
            link: 'blocks/progress-circular'
          },
          {
            name: 'Progress horizontal',
            link: 'blocks/progress-horizontal'
          },
          {
            name: 'Sparklines large',
            link: 'blocks/sparklines-large'
          },
          {
            name: 'Sparklines small',
            link: 'blocks/sparklines-small'
          },
          {
            name: 'Statistics',
            link: 'blocks/statistics'
          }
        ]
      },
      {
        name: 'Applications',
        icon: AnalyticsTwoToneIcon,
        link: '/accent-sidebar/applications',
        items: [
          {
            name: 'Calendar',
            link: 'applications/calendar'
          },
          {
            name: 'File Manager',
            link: 'applications/file-manager'
          },
          {
            name: 'Jobs Platform',
            link: 'applications/jobs-platform'
          },
          {
            name: 'Mailbox',
            link: 'applications/mailbox/inbox'
          },
          {
            name: 'Messenger',
            link: 'applications/messenger'
          },
          {
            name: 'Projects Board',
            link: 'applications/projects-board'
          }
        ]
      } */
      ].filter((a) => a.isVisible)
    },

    {
      heading: 'Contracts',
      isVisible: menuPermissions.isAuthorised_Contract_Settings || menuPermissions.isAuthorised_LandContract_List
      || menuPermissions.isAuthorised_LandContract_Create,
      isExpanded: false,
      items: [
        {
          name: 'Land',
          icon: AnalyticsTwoToneIcon,
          isVisible: menuPermissions.isAuthorised_LandContract_List || menuPermissions.isAuthorised_LandContract_Create,
          isExpanded: false,
          /*  link: '/accent-sidebar/products/land', */
          items: [
            {
              name: 'List',
              link: '/accent-sidebar/contracts/land/list',
              isVisible: menuPermissions.isAuthorised_LandContract_List,
              isExpanded: false,
              parent: 'Contracts'
            },
            {
              name: 'Create',
              link: '/accent-sidebar/contracts/land/create',
              isVisible: menuPermissions.isAuthorised_LandContract_Create,
              isExpanded: false,
              parent: 'Contracts'
            }
          ].filter((a) => a.isVisible)
        },
        {
          name: 'Settings',
          icon: SupportIcon,
          isVisible: menuPermissions.isAuthorised_Contract_Settings,
          isExpanded: false,
          /*  link: '/accent-sidebar/products/land', */
          items: [
            {
              name: 'Support',
              link: '/accent-sidebar/contracts/settings/support',
              isVisible: true,
              isExpanded: false,
              parent: 'Settings'
            },
            {
              name: 'Contract Year Period',
              link: '/accent-sidebar/contracts/settings/contractyearperiod',
              isVisible: true,
              isExpanded: false,
              parent: 'Settings'
            },
            {
              name: 'Contract Period',
              link: '/accent-sidebar/contracts/settings/contractperiod',
              isVisible: true,
              isExpanded: false,
              parent: 'Settings'
            },
            {
              name: 'Contract Year',
              link: '/accent-sidebar/contracts/settings/contractyear',
              isVisible: true,
              isExpanded: false,
              parent: 'Settings'
            },
            {
              name: 'Contract Supplier Product',
              link: '/accent-sidebar/contracts/settings/contractsupplierproduct',
              isVisible: true,
              isExpanded: false,
              parent: 'Settings'
            }
          ].filter((a) => a.isVisible)
        }
      ].filter((a) => a.isVisible)
    },
    {
      heading: 'Products',
      isVisible: menuPermissions.isAuthorised_Products,
      isExpanded: false,
      items: [
        {
          name: 'Land',
          icon: LandscapeIcon,
          isVisible: menuPermissions.isAuthorised_Land,
          isExpanded: false,
          /*  link: '/accent-sidebar/products/land', */
          items: [
            {
              name: 'Browse Data Files',
              link: '/accent-sidebar/products/land/sales/browse-data-files/normal',
              isVisible: menuPermissions.isAuthorised_Land_Browse_Data_Files,
              isExpanded: false,
              parent: 'Products'
            },
            {
              name: 'File Status',
              link: '/accent-sidebar/products/land/sales/file-status/view',
              isVisible: menuPermissions.isAuthorised_Land_File_Status,
              isExpanded: false,
              parent: 'Products'
            },
            {
              name: 'Load Data File',
              link: '/accent-sidebar/products/land/sales/check-data-file',
              isVisible: menuPermissions.isAuthorised_Land_Load_Data_File,
              isExpanded: false,
              parent: 'Products'
            },
            {
              name: 'Land Override',
              link: '/accent-sidebar/products/land/sales/land-override',
              isVisible: menuPermissions.isAuthorised_Land_Land_Override,
              isExpanded: false,
              parent: 'Products'
            },
            {
              name: 'Override Remittances',
              link: '/accent-sidebar/products/land/sales/override-remittances',
              isVisible: menuPermissions.isAuthorised_Land_Override_Remittances,
              isExpanded: false,
              parent: 'Products'
            },
            {
              name: 'Override Remittances History',
              link: '/accent-sidebar/products/land/sales/override-remittances-history',
              isVisible: menuPermissions.isAuthorised_Land_Override_Remittances_History,
              isExpanded: false,
              parent: 'Products'
            }
          ].filter((a) => a.isVisible)
        }
      ].filter((a) => a.isVisible)
    },
    {
      heading: 'Reports',
      isVisible: menuPermissions.isAuthorised_Reports,
      isExpanded: false,
      items: [
        {
          name: 'Ticketing',
          icon: AirplaneTicketIcon,
          isVisible: menuPermissions.isAuthorised_Ticketing,
          isExpanded: false,
          items: [
            {
              name: 'Agency',
              isVisible: menuPermissions.isAuthorised_Agency_Report,
              isExpanded: false,
              parent: 'Ticketing',
              items: [
                {
                  name: 'Agency By Airline Annual',
                  link: '/accent-sidebar/reports/ticketing/agency/agency-by-airline-annual',
                  isVisible:
                    menuPermissions.isAuthorised_Agency_By_Airline_Annual,
                  isExpanded: false,
                  parent: 'Agency'
                },
                {
                  name: 'Agency By Airline Monthly',
                  link: '/accent-sidebar/reports/ticketing/agency/agency-by-airline-monthly',
                  isVisible:
                    menuPermissions.isAuthorised_Agency_By_Airline_Monthly,
                  isExpanded: false,
                  parent: 'Agency'
                },
                {
                  name: 'Agency By Airline Comm Annual',
                  link: '/accent-sidebar/reports/ticketing/agency/agency-by-airline-commission-annual',
                  isVisible:
                    menuPermissions.isAuthorised_Agency_By_Airline_Comm_Annual,
                  isExpanded: false,
                  parent: 'Agency'
                },
                {
                  name: 'Agents Sales Existing Annual',
                  link: '/accent-sidebar/reports/ticketing/agency/agents-sales-existing-annual',
                  isVisible:
                    menuPermissions.isAuthorised_Agents_Sales_Existing_Annual,
                  isExpanded: false,
                  parent: 'Agency'
                },
                {
                  name: 'Agents Sales Existing Weekly',
                  link: '/accent-sidebar/reports/ticketing/agency/agents-sales-existing-weekly',
                  isVisible:
                    menuPermissions.isAuthorised_Agents_Sales_Existing_Weekly,
                  isExpanded: false,
                  parent: 'Agency'
                },
                {
                  name: 'Agents New Business',
                  link: '/accent-sidebar/reports/ticketing/agency/agents-new-business',
                  isVisible: menuPermissions.isAuthorised_Agents_New_Business,
                  isExpanded: false,
                  parent: 'Agency'
                },
                {
                  name: 'Agency Sales Summary Annual',
                  link: '/accent-sidebar/reports/ticketing/agency/agents-sales-summary-annual',
                  isVisible:
                    menuPermissions.isAuthorised_Agency_Sales_Summary_Annual,
                  isExpanded: false,
                  parent: 'Agency'
                },
                {
                  name: 'Agency Sales Summary Monthly',
                  link: '/accent-sidebar/reports/ticketing/agency/agents-sales-summary-monthly',
                  isVisible:
                    menuPermissions.isAuthorised_Agency_Sales_Summary_Monthly,
                  isExpanded: false,
                  parent: 'Agency'
                },
                /*               {
                name: 'Agents Ticket Usage',
                link: '/accent-sidebar/reports/ticketing/agency/agents-ticket-usage',
                isVisible: menuPermissions.isAuthorised_Agents_Ticket_Usage,
                isExpanded: false,
                parent: 'Agency'
              }, */
                {
                  name: 'Agent Vs BSP',
                  link: '/accent-sidebar/reports/ticketing/agency/agents-vs-bsp',
                  isVisible: menuPermissions.isAuthorised_Agent_Vs_BSP,
                  isExpanded: false,
                  parent: 'Agency'
                },
                {
                  name: 'Detailed Ticket Report',
                  link: '/accent-sidebar/reports/ticketing/agency/detailed-ticket-report',
                  isVisible:
                    menuPermissions.isAuthorised_Detailed_Ticket_Report,
                  isExpanded: false,
                  parent: 'Agency'
                },
                {
                  name: 'Self Plated Ticket Report',
                  link: '/accent-sidebar/reports/ticketing/agency/self-plated-ticket-report',
                  isVisible:
                    menuPermissions.isAuthorised_Self_Plate_Detailed_Ticket_Report,
                  isExpanded: false,
                  parent: 'Agency'
                },
                {
                  name: 'Ticket Sector Report',
                  link: '/accent-sidebar/reports/ticketing/agency/ticket-sector-report',
                  isVisible: menuPermissions.isAuthorised_Ticket_Sector_Report,
                  isExpanded: false,
                  parent: 'Agency'
                }
              ].filter((a) => a.isVisible)
            },
            {
              name: 'Airline',
              isVisible: menuPermissions.isAuthorised_Airline_Report,
              isExpanded: false,
              parent: 'Ticketing',
              items: [
                {
                  name: 'Airline By Agency Annual',
                  link: '/accent-sidebar/reports/ticketing/airline/airline-by-agency-annual',
                  isVisible:
                    menuPermissions.isAuthorised_Airline_By_Agency_Annual,
                  isExpanded: false,
                  parent: 'Airline'
                },
                {
                  name: 'Airline By Agency Monthly',
                  link: '/accent-sidebar/reports/ticketing/airline/airline-by-agency-monthly',
                  isVisible:
                    menuPermissions.isAuthorised_Airline_By_Agency_Monthly,
                  isExpanded: false,
                  parent: 'Airline'
                },
                {
                  name: 'Airline Sales Existing Weekly',
                  link: '/accent-sidebar/reports/ticketing/airline/airline-sales-existing-weekly',
                  isVisible:
                    menuPermissions.isAuthorised_Airline_Sales_Existing_Weekly,
                  isExpanded: false,
                  parent: 'Airline'
                },
                {
                  name: 'Airline Sales Summary Annual',
                  link: '/accent-sidebar/reports/ticketing/airline/airline-sales-summary-annual',
                  isVisible:
                    menuPermissions.isAuthorised_Airline_Sales_Summary_Annual,
                  isExpanded: false,
                  parent: 'Airline'
                },
                {
                  name: 'Airline Sales Summary Monthly',
                  link: '/accent-sidebar/reports/ticketing/airline/airline-sales-summary-monthly',
                  isVisible:
                    menuPermissions.isAuthorised_Airline_Sales_Summary_Monthly,
                  isExpanded: false,
                  parent: 'Airline'
                }
              ]
            }
          ].filter((a) => a.isVisible)
        },
        {
          name: 'General',
          icon: SummarizeIcon,
          isVisible: menuPermissions.isAuthorised_General_Report,
          isExpanded: false,
          items: [
            {
              name: 'Agent Audit Trail',
              link: '/accent-sidebar/reports/general/agent-audit-trail',
              isVisible: menuPermissions.isAuthorised_Agent_AuditTrail,
              isExpanded: false
            },
            {
              name: 'Agent Extended',
              link: '/accent-sidebar/reports/general/agent-extended',
              isVisible: menuPermissions.isAuthorised_Agent_Extended,
              isExpanded: false
            },
            {
              name: 'Agent Net Outstanding',
              link: '/accent-sidebar/reports/general/agent-net-outstanding',
              isVisible: menuPermissions.isAuthorised_Agent_NetOutstanding,
              isExpanded: false
            },
            {
              name: 'BDM Weekly',
              link: '/accent-sidebar/reports/general/bdm-weekly',
              isVisible: menuPermissions.isAuthorised_BDM_Weekly,
              isExpanded: false
            },
            {
              name: 'Detailed PCC',
              link: '/accent-sidebar/reports/general/pcc-list',
              isVisible: menuPermissions.isAuthorised_Pcc_List,
              isExpanded: false
            },
            {
              name: 'EDM Subscriber List',
              link: '/accent-sidebar/reports/general/edm-subscriber-list',
              isVisible: menuPermissions.isAuthorised_EDM_Subscriber_List,
              isExpanded: false
            },
            {
              name: 'Financial Details Report',
              link: '/accent-sidebar/reports/general/financial-details',
              isVisible: menuPermissions.isAuthorised_Finance_Details_Report,
              isExpanded: false
            },            
            {
              name: 'Land Overrides',
              link: '/accent-sidebar/reports/general/land-overrides',
              isVisible: menuPermissions.isAuthorised_Land_Overrides,
              isExpanded: false
            },
            {
              name: 'Private Fares',
              link: '/accent-sidebar/reports/general/private-fares',
              isVisible: menuPermissions.isAuthorised_Private_Fares,
              isExpanded: false
            },
            {
              name: 'Product Sales',
              link: '/accent-sidebar/reports/general/product-sales',
              isVisible: menuPermissions.isAuthorised_Product_Sales,
              isExpanded: false
            },
            {
              name: 'System Extended',
              link: '/accent-sidebar/reports/general/system-extended',
              isVisible: menuPermissions.isAuthorised_System_Extended,
              isExpanded: false
            }
          ].filter((a) => a.isVisible)
        }
      ].filter((a) => a.isVisible)
    },
    {
      heading: 'Admin',
      isVisible: menuPermissions.isAuthorised_Admin_Panel,
      isExpanded: false,
      items: [
        {
          name: 'Version Management',
          link: '/accent-sidebar/admin/version',
          icon: SettingsSuggestIcon,
          isVisible: menuPermissions.isAuthorised_Admin_Version,
          isExpanded: false
        },
        {
          name: 'Reference Management',
          link: '/accent-sidebar/admin/reference',
          icon: RoomPreferencesIcon,
          isVisible: menuPermissions.isAuthorised_Admin_Reference,
          isExpanded: false
        },
        {
          name: 'Email Builder Template',
          link: '/accent-sidebar/admin/emailBuilderTemplate',
          icon: MarkEmailUnreadIcon,
          isVisible: menuPermissions.isAuthorised_Admin_Email_Builder_Template,
          isExpanded: false
        },
        {
          name: 'Airport Zone Management',
          link: '/accent-sidebar/admin/airportCountryZone',
          icon: LanguageIcon,
          isVisible: menuPermissions.isAuthorised_Admin_Airport_Zone,
          isExpanded: false
        },
        {
          name: 'BSP Week Manage',
          link: '/accent-sidebar/admin/bspWeekManage',
          icon: MarkEmailUnreadIcon,
          isVisible: menuPermissions.isAuthorised_Admin_Email_Builder_Template,
          isExpanded: false
        }
      ].filter((a) => a.isVisible)
    }

    /* {
    heading: 'Management',
    items: [
      {
        name: 'Users',
        icon: AssignmentIndTwoToneIcon,
        link: '/accent-sidebar/management/users',
        items: [
          {
            name: 'List',
            link: 'management/users/list'
          },
          {
            name: 'User Profile',
            link: 'management/users/single'
          }
        ]
      },
      {
        name: 'Projects',
        icon: AccountTreeTwoToneIcon,
        link: '/accent-sidebar/management/projects/list'
      },
      {
        name: 'Commerce',
        icon: StorefrontTwoToneIcon,
        link: '/accent-sidebar/management/commerce',
        items: [
          {
            name: 'Shop',
            link: 'management/commerce/shop'
          },
          {
            name: 'List',
            link: 'management/commerce/products/list'
          },
          {
            name: 'Details',
            link: 'management/commerce/products/single/1'
          },
          {
            name: 'Create',
            link: 'management/commerce/products/create'
          }
        ]
      },
      {
        name: 'Invoices',
        icon: ReceiptTwoToneIcon,
        link: '/accent-sidebar/management/invoices',
        items: [
          {
            name: 'List',
            link: 'management/invoices/list'
          },
          {
            name: 'Details',
            link: 'management/invoices/single'
          }
        ]
      }
    ]
  },

  {
    heading: 'Extra Pages',
    items: [
      {
        name: 'Auth Pages',
        icon: VpnKeyTwoToneIcon,
        link: '/auth',
        items: [
          {
            name: 'Login',
            items: [
              {
                name: 'Basic',
                link: '/account/login-basic'
              },
              {
                name: 'Cover',
                link: '/account/login-cover'
              }
            ]
          },
          {
            name: 'Register',
            items: [
              {
                name: 'Basic',
                link: '/account/register-basic'
              },
              {
                name: 'Cover',
                link: '/account/register-cover'
              },
              {
                name: 'Wizard',
                link: '/account/register-wizard'
              }
            ]
          },
          {
            name: 'Recover Password',
            link: '/account/recover-password'
          }
        ]
      },
      {
        name: 'Status',
        icon: ErrorTwoToneIcon,
        link: '/status',
        items: [
          {
            name: 'Error 404',
            link: '/status/404'
          },
          {
            name: 'Error 500',
            link: '/status/500'
          },
          {
            name: 'Maintenance',
            link: '/status/maintenance'
          },
          {
            name: 'Coming Soon',
            link: '/status/coming-soon'
          }
        ]
      }
    ]
  },
  {
    heading: 'Foundation',
    items: [
      {
        name: 'Overview',
        link: '/overview',
        icon: DesignServicesTwoToneIcon
      },
      {
        name: 'Documentation',
        icon: SupportTwoToneIcon,
        link: '/docs'
      }
    ] 
  }*/
  ].filter((a) => a.isVisible);

  return menuItems;
}

