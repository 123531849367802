import {
  Checkbox} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { renderActions } from 'src/ems/components/common/CustomDataGrid/CustomDataGridServices/ActionButtonService';
import { checkUserHasRole } from 'src/ems/services/auth.service';
import { role } from 'src/ems/enums/roles.enums';

const columns = (handleOpenDelete, setselectedRow, handleOpen) => {
  const theme = useTheme();
  const handleDeleteAction = (data) => {                   
    handleOpenDelete();
    setselectedRow(data.row);
    return;
  }
  
  const handleEditAction = (data) => {
    handleOpen(data);
  }

  console.log(handleEditAction);

  return [
    {
      field: 'airline',
      headerName: 'Airline',  
      flex: 1,
      width: 200,
    },     
    {
      field: 'bookingClass',
      headerName: 'Booking class',
      width: 100,
      flex: 2,
    },    
    {
      field: 'classTypeReference',
      headerName: 'Class Type',
      width: 100,
      flex: 2,
    }, 
    {
      field: 'action',
      headerName: 'Action',
      width: 180,
      flex: 1,
      visible: checkUserHasRole(role.EDIT_CLASS_OF_SERVICE) || checkUserHasRole(role.	DELETE_CLASS_OF_SERVICE),
      renderCell: (params) => {
        return (renderActions(theme, params, null, 
          checkUserHasRole(role.DELETE_CLASS_OF_SERVICE) ? handleDeleteAction : null,
          checkUserHasRole(role.EDIT_CLASS_OF_SERVICE) ? handleEditAction : null))
      } 
    }, 
  ];
};

export default columns;
