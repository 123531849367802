// import ClassOfServiceEntity from 'src/ems/models/supplier_models/supplier_class_of_service_entity_details';
import SupplierAirlines from '../models/supplier_models/supplier_airlines';
import http from './http.service';
import ClassOfService from 'src/ems/models/supplier_models/supplier_class_of_service_details';

const apiEndpoint = '/supplier/ClassOfService';

export async function list(signal?: any) {
  const response = await http.get<ClassOfService[]>(
    (apiEndpoint + '/list'),
    {
      signal: signal
    }
  );
  return response;
}

export async function create(data: ClassOfService) {
  await http.post(apiEndpoint + '/create', data);
}

export async function update(data: ClassOfService) {
  await http.put(apiEndpoint + '/update', data);
}

export async function deleteClassOfService(id: number) {
  await http.post(apiEndpoint + '/delete/' + id);
}

export async function getAirlines(signal: any) {
  const response = await http.get<SupplierAirlines[]>(
    apiEndpoint + '/getairlines', {
      signal: signal
    }
  );
  return response;
}


// export async function listEntity(id: string, signal?: any) {
//   const response = await http.get<ClassOfServiceEntity[]>(
//     apiEndpoint + '/list/' + id,
//     {
//       signal: signal
//     }
//   );
//   return response;
// }

// export async function updateEntity(data: ClassOfServiceEntity) {
//   await http.post(apiEndpoint + '/updateEntity', data);
// }

// export async function deleteEntity(data: any) {
//   await http.post(apiEndpoint + '/deleteEntity', data);
// }

export default {
  list,
  deleteClassOfService,
  create,
  update,
  getAirlines,
  // listEntity,
  // updateEntity,
  // deleteEntity
};
