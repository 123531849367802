
import * as Yup from 'yup';

export const classOfServiceDetailsSchema = Yup.object().shape({
  bookingClass: Yup.string()
           .max(3, 'Max is 3 characters')
           .required('Booking class field is required'),
  airlineId: Yup.string().typeError('').required('Required'),
  classTypeReferenceId: Yup.string().typeError('').required('Required')
});
