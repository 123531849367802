import {
  Backdrop,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Typography,
  Divider
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import GridStyle from './GridStyle';

interface DataTableProps {
  rows: any[];
  columns: any[];
  hideColumnFilter?: boolean;
  open?: boolean;
  getRowId: (row: any) => string | number;
  defaultPageSize?: number;
  sx?: React.CSSProperties; // Specify sx prop as optional
  hideToolbar?: boolean; // Add hideToolbar prop
  [key: string]: any;
}

export default function DataTable({
  rows,
  columns,
  hideColumnFilter = true,
  open = false,
  getRowId,
  defaultPageSize = 50,
  sx, // Receive sx as a prop
  hideToolbar = false, // Assign default value
  setRowSpacing = true,
  ...rest
}: DataTableProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: defaultPageSize,
    page: 0
  });

  // Check if rows is null or undefined
  if (rows == null) {
    return (
      <Typography variant="body1" align="center">
        No records found.
      </Typography>
    );
  }

  // Check if sx prop is provided, if not, use GridStyle
  const style = sx ? sx : GridStyle;

  const renderCellContent = (row: any, column: any) => {
    if (column.renderCell) {
      // Use custom renderCell function if provided
      return column.renderCell({
        value: row[column.field],
        row,
        rowIndex: rows.indexOf(row),
        columnIndex: columns.indexOf(column)
      });
    } else if (column.valueGetter) {
      // Use valueGetter function if provided
      return column.valueGetter({ row });
    } else {
      // Default render, return value from row
      return row[column.field];
    }
  };

  if (isMobile) {
    // Render rows as cards for mobile view
    return (
      <>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {rows.length === 0 ? (
          <Typography variant="body1" align="center">
            No records found.
          </Typography>
        ) : (
          <Grid container spacing={2}>
            {rows.map((row, rowIndex) => (
              <Grid item key={getRowId(row)} xs={12}>
                <Card>
                  <CardContent>
                    {columns.map((column, columnIndex) => (
                      <React.Fragment key={`${getRowId(row)}-${column.field}`}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%'
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 'bold',
                              width: '50%',
                              textAlign: 'left'
                            }}
                          >
                            {column.headerName} { column.headerName ? ':' : ''}
                          </span>
                          <span
                            style={{
                              width: '50%',
                              textAlign: 'right'
                            }}
                          >
                            {renderCellContent(row, column)}{' '}
                            {/* Render cell content */}
                            {/*    {column.renderCell
                              ? column.renderCell({
                                  value: row[column.field],
                                  row,
                                  rowIndex,
                                  columnIndex
                                })
                              : row[column.field]} */}
                          </span>
                        </div>
                        {columnIndex !== columns.length - 1 && (
                          <Divider variant="middle" />
                        )}
                      </React.Fragment>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </>
    );
  }

  // Render DataGrid for non-mobile view
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {rows.length === 0 ? (
        <Typography variant="body1" align="center">
          No records found.
        </Typography>
      ) : (
        <div style={{ width: '100%' }}>
          <DataGrid
            {...rest}
            autoHeight
            slots={!hideToolbar ? { toolbar: GridToolbar } : undefined}
            columns={columns}
            rows={rows}
            disableColumnFilter={hideColumnFilter}
            getRowId={getRowId}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            getRowHeight={() => 'auto'}
            getRowSpacing={(params) => (!setRowSpacing ? {} : {
              top: params.isFirstVisible ? 0 : 5,
              bottom: params.isLastVisible ? 0 : 5
            })}
            sx={style} // Apply sx prop or default style
          />
        </div>
      )}
    </>
  );
}

DataTable.defaultProps = {
  density: 'standard'
};
