import { Link as RouterLink } from 'react-router-dom';
import {
  Breadcrumbs,
  Box,
  Grid,
  Link,
  Typography} from '@mui/material';
import CommonService from 'src/ems/services/common.service';

const PageHeader = () => {

  const rootPath = CommonService.getCurrentRootPath();

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" component="h3" gutterBottom>
              Class of Service
            </Typography>
            <Breadcrumbs maxItems={3} aria-label="breadcrumb">
              <Link color="inherit" href="#">
                Home
              </Link>
              <Link
                component={RouterLink}
                color="inherit"
                to={rootPath + `/supplier/classofservice`}
              >
                Class of Service
              </Link>
              {/* <Typography color="text.primary">{selectedType}</Typography> */}
            </Breadcrumbs>
          </Box>
        </Box>
      </Grid>

    </Grid>
  );
};

export default PageHeader;
