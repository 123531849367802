import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import Input from 'src/ems/components/rhf-components/Input';
import Dropdown from 'src/ems/components/rhf-components/Dropdown';
import FormDropDownProps from 'src/ems/models/form_dropdown_props';

interface ClassOfServiceProps {
  modalProps: any;
  updateClassOfService: any;
  addClassOfService: any;
  references: any;
}

const ClassOfServiceModal: React.FC<ClassOfServiceProps> = ({
  modalProps,
  updateClassOfService,
  addClassOfService,
  references = []
}) => {
  const { open, handleClose, classOfServiceToEdit, setclassOfServiceToEdit, airlines } = modalProps;
  const { control, setValue, handleSubmit, formState, clearErrors } =
    useFormContext();
  const [isAdd, setisAdd] = useState(true);

  useEffect(() => {
    if (classOfServiceToEdit) {
      setisAdd(false);
      setclassOfServiceDetails(classOfServiceToEdit);
    }else{
      setValueFields(null);
      setisAdd(true);
    }
  }, [classOfServiceToEdit]);

  const { isSubmitting } = formState;

  const setclassOfServiceDetails = (data) => {
    setclassOfServiceToEdit(data);

    if (data !== null) {
      setValueFields(data);
    }
  };

  const setValueFields = (data) => {
    setValue('classTypeReferenceId', data !== null ? data.row.classTypeReferenceId : '');
    setValue('airlineId', data !== null ? data.row.airlineId : '');
    setValue('bookingClass', data !== null ? data.row.bookingClass : '');
    setValue('classOfServiceId', data !== null ? data.row.classOfServiceId : '');
  };

  const resetForm = () => {
    clearErrors();
    setValueFields(null);
    setclassOfServiceDetails(null);
    setclassOfServiceToEdit(null);
    setisAdd(true);
  };

  const onSubmit = async (data) => {
    if (isAdd) {
      await addClassOfService(data);
    } else {
      await updateClassOfService(data);
    }
    resetForm();
  };

  const modalTitle = classOfServiceToEdit ? "Edit" : "Add New";

  const classType = references.filter(
    (reference: FormDropDownProps) => reference.type == 'TCLASS'
  ).sort((a, b) => a.label.localeCompare(b.label));

  const airlineOptions = airlines.map((c) => ({
    value: c.entityId,
    label: c.name + ' (' + c.supplierCode + ')'
  }));

  return (
    <Dialog open={open} 
      onClose={() => {
        resetForm();
        handleClose();
      }} 
      maxWidth="md" fullWidth>
      <Box sx={{ mb: 2 }}>
        <DialogTitle>{`${modalTitle.toUpperCase()}`}</DialogTitle>
      </Box>
      <DialogContent>
        <Grid alignItems="flex-end" container spacing={3}>
          <Grid item xs={12} md={12}>
            <Dropdown
              name="airlineId"
              label="Select Airline"
              options={airlineOptions}
              control={control}
              disabled={false}
            />
          </Grid>            
          <Grid item xs={12} md={12}>
            <Input
              name="bookingClass"
              label="Booking Class"
              control={control}
              charLengthLimit={3}
              disabled={false}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Dropdown
              name="classTypeReferenceId"
              label="Select Class Type"
              options={classType}
              control={control}
              disabled={false}
            />
          </Grid>          
        </Grid>
        <DialogActions>
          <Button
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            color="primary"
            variant="contained"
            sx={{ marginRight: '10px' }}
            style={{ fontWeight: 'bold' }}
            onClick={async () => {
              await handleSubmit((data) => {
                onSubmit(data);
                handleClose();
              })();
            }}
            disabled={isSubmitting}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ fontWeight: 'bold' }}
            onClick={() => {
              resetForm();
              handleClose();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ClassOfServiceModal;
